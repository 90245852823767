<!--
 * @Author: wangwei
 * @Date: 2020-12-29 16:05:36
 * @LastEditTime: 2021-01-15 16:33:06
 * @LastEditors: Please set LastEditors
 * @Description: 手机号管理
 * @FilePath: /java-pc/src/views/member/center/accountSafe.vue
-->
<template>
  <div class="sld_phone_mange">
    <MemberTitle :memberTitle="L['手机号管理']"></MemberTitle>
    <div class="container">
      <div class="title">{{ L["手机号管理"] }}</div>
      <div class="mange_con">
        <span v-if="!memberInfo.data.memberMobile != true " class="current"
          >{{ L["当前手机号"] }} {{ memberInfo.data.memberMobile }}</span>
        <el-input
          v-if="!memberInfo.data.memberMobile"
          class="password input"
          v-model="new_mobile"
          :placeholder="L['请输入手机号']"
          type="number"
          style="margin-bottom: 20px"
        >
        </el-input>
        <div v-if="step == 1 || step == 0" class="sms_code_con flex_row_center_center">
          <el-input
            v-model="old_sms_code"
            :placeholder="L['请输入短信验证码']"
            type="number"
          ></el-input>
          <div class="get_sms pointer" @click="getSmsCode">
            {{ countDownM ? countDownM + L["s后获取"] : L["获取验证码"] }}
          </div>
        </div>
        <el-input
          v-if="step == 2"
          class="password input"
          v-model="new_mobile"
          :placeholder="L['请输入新手机号']"
          type="number"
        >
        </el-input>
        <div
          v-if="step == 2"
          class="sms_code_con flex_row_center_center margin"
        >
          <el-input
            v-model="new_sms_code"
            :placeholder="L['请输入短信验证码']"
            type="number"
          ></el-input>
          <div class="get_sms pointer" @click="getSmsCode">
            {{ countDownM ? countDownM + L["s后获取"] : L["获取验证码"] }}
          </div>
        </div>
        <div class="error_tip">
          <span
            v-if="errorMsg"
            style="color: #e1251b; font-size: 14px"
            class="iconfont icon-jubao"
          ></span>
          {{ errorMsg }}
        </div>
        <div class="next flex_row_center_center" @click="next">
          {{ step == 1 ? L["下一步"] : L["确定"] }}
        </div>
      </div>
      <div class="manage_tips">
        <p class="tips_title">{{ L["温馨提示"] }}：</p>
        <p>• {{ L["为了保障您的账号安全，变更重要信息需进行身份验证。"] }}</p>
        <p>• {{ L["变更过程中有任何疑问请联系在线客服解决。"] }}</p>
        <p>
          •
          {{ L["如手机号/邮箱已不再使用无法获取验证码，请联系在线客服解决。"] }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ElInput, ElMessage, ElMessageBox } from "element-plus";
import { getCurrentInstance, reactive, ref, watch,onMounted } from "vue";
import { useStore } from "vuex";
import MemberTitle from "../../../components/MemberTitle";

export default {
  name: "PhoneMange",
  components: {
    ElInput,
    MemberTitle,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const errorMsg = ref("");
    const L = proxy.$getCurLanguage();
    const store = useStore();
    const step = ref(1);
    const old_sms_code = ref("");
    const new_sms_code = ref("");
    const new_mobile = ref("");
    const timeOutId = ref(""); //定时器的返回值
    const countDownM = ref(0); //短信验证码倒计时
    const memberInfo = reactive({ data: store.state.memberInfo });
    const preventFre = ref(false);
    // const first_mobileNumber = ref('')  //没有手机号的时候添加手机号的输入内容

    const getSmsCode = () => {
      if (preventFre.value) {
        return false;
      }
      preventFre.value = true;

      if (step.value == 2 || (step.value == 1 && !memberInfo.data.memberMobile)) {
        //手机号验证
        if (!new_mobile.value) {
          preventFre.value = false;
          errorMsg.value = L["请输入手机号"];
          return false;
        } else {
          let checkMobile = proxy.$checkPhone(new_mobile.value);
          if (checkMobile !== true) {
            errorMsg.value = checkMobile;
            preventFre.value = false;
            return false;
          }
        }
      }

      if (countDownM.value) {
        return false;
      }
      var param = {};
      param.type ='wxAuth';
      // param.changeType = step.value == 1  ? "old" : "new";
      // param.verifyAddr = step.value == 1 ? memberInfo.data.memberMobile : new_mobile.value;
      param.mobile = step.value == 1 ? memberInfo.data.memberMobile : new_mobile.value;
      console.log(param)

      // proxy.$get("v3/msg/front/commons/sendVerifyCode", param).then((res) => {
      //   if (res.state == 200) {
      //     errorMsg.value = "";
      //     countDownM.value = 60;
      //     countDown();
      //   } else {
      //     preventFre.value = false;
      //     ElMessage.error(res.msg);
      //   }
      // }); 
        proxy.$get("v3/msg/front/commons/smsCode", param).then((res) => {
        if (res.state == 200) {
          errorMsg.value = "";
          countDownM.value = 60;
          countDown();
        } else {
          preventFre.value = false;
          ElMessage.error(res.msg);
        }
      }); 
    };

    const next = () => {
      preventFre.value = false;
      if (step.value == 1 && !memberInfo.data.memberMobile == false) {
        //验证码校验
        if (!old_sms_code.value) {
          errorMsg.value = L["请输入短信验证码"];
          return false;
        } else {
          let checkSmsCode = proxy.$checkSmsCode(old_sms_code.value);
          if (checkSmsCode !== true) {
            errorMsg.value = checkSmsCode;
            return false;
          }
        }
        proxy
          .$post("v3/member/front/memberPassword/verifyOldMobile", {
            memberMobile: memberInfo.data.memberMobile,
            smsCode: old_sms_code.value,
          })
          .then((res) => {
            console.log(res);
            if (res.state == 200) {
              step.value = 2;
              clearTimeout(timeOutId.value);
              countDownM.value = 0;
              errorMsg.value = "";
            } else {
              errorMsg.value = res.msg;
            }
          });
      }else if(step.value != 1 && !memberInfo.data.memberMobile){       //用户信息中没有手机号时调用
        if(!new_mobile){
          ElMessage.error('请输入手机号')
        }
        let checkMobilmessage = proxy.$checkPhone(new_mobile.value)
        if(checkMobilmessage != true){
           ElMessage.error(checkMobilmessage)
        }else{
          const param = {}
          param.memberMobile = new_mobile.value
          param.smsCode = old_sms_code.value
          param.isUnbound = '1'
          proxy
          .$post('v3/member/front/memberPassword/editMobile',param)
          .then(ret => {
            if(ret.state == 200){
              const memberInfos = JSON.parse(window.localStorage.getItem('memberInfo'))
              memberInfos.memberMobile = new_mobile.value
              window.localStorage.setItem('memberInfo',JSON.stringify(memberInfos))
              // step.value = 2
              memberInfo.data.memberMobile = new_mobile.value
              old_sms_code.value = ''
              countDownM.value = 0
              clearInterval(timeOutId.value)
            }
          })
        }

      }else if (step.value == 2) {
        //验证码校验
        if (!new_sms_code.value) {
          errorMsg.value = L["请输入短信验证码"];
          return false;
        } else {
          let checkSmsCode = proxy.$checkSmsCode(new_sms_code.value);
          if (checkSmsCode !== true) {
            errorMsg.value = checkSmsCode;
            return false;
          }
        }
        proxy
          .$post("v3/member/front/memberPassword/editMobile", {
            memberMobile: new_mobile.value,
            smsCode: new_sms_code.value,
          })
          .then((res) => {
            if (res.state == 200) {
              clearTimeout(timeOutId.value);
              //更新个人信息
              memberInfo.data.memberMobile = new_mobile.value;
              store.commit("updateMemberInfo", memberInfo.data);
              countDownM.value = 0;
              ElMessage.success(res.msg);
              step.value = 1;
              old_sms_code.value = "";
              errorMsg.value = "";
              new_sms_code = ''
              new_mobile = ''
            } else if (res.state == 267) {
              ElMessageBox.confirm(res.msg, L["提示"], {
                confirmButtonText: L["确定"],
                cancelButtonText: L["取消"],
                type: "warning",
              }).then(() => {
                proxy
                  .$post("v3/member/front/memberPassword/editMobile", {
                    memberMobile: new_mobile.value,
                    smsCode: new_sms_code.value,
                    isUnbound: 1,
                  })
                  .then((res) => {
                    if (res.state == 200) {
                      //更新个人信息
                      memberInfo.data.memberMobile = new_mobile.value;
                      store.commit("updateMemberInfo", memberInfo.data);
                      ElMessage.success(res.msg);
                      step.value = 1;
                      old_sms_code.value = "";
                    } else {
                      ElMessage.error(res.msg);
                    }
                  });
              });
            } else {
              errorMsg.value = res.msg;
            }
          });
      }
    };
    //倒计时
    const countDown = () => {
      countDownM.value--;
      if (countDownM.value == 0) {
        preventFre.value = false;
        clearTimeout(timeOutId.value);
      } else {
        timeOutId.value = setTimeout(countDown, 1000);
      }
    };

    watch([old_sms_code, new_sms_code, new_mobile], () => {
      old_sms_code.value = old_sms_code.value.substring(0, 6);
      new_sms_code.value = new_sms_code.value.substring(0, 6);
      new_mobile.value = new_mobile.value.substring(0, 11);
      errorMsg.value = "";
    });
    onMounted(() => {
      console.log(step.value);
      console.log(memberInfo.data.memberMobile)
      if(!memberInfo.data.memberMobile) step.value = 0
    });
    return {
      L,
      memberInfo,
      countDownM,
      getSmsCode,
      new_mobile,
      old_sms_code,
      new_sms_code,
      next,
      step,
      errorMsg,
    };
  },
};
</script>

<style lang="scss" scoped>
.sld_phone_mange {
  width: 1007px;
  float: left;
  margin-left: 10px;

  .container {
    background-color: white;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #eaeaea;
    padding: 25px 40px;

    .title {
      font-size: 18px;
      border-bottom: 1px dashed #eaeaea;
      padding-bottom: 25px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .mange_con {
      width: 360px;
      margin: 62px auto 0;

      .current {
        line-height: 56px;
        font-size: 14px;
        color: #333333;
        width: 100%;
        text-align: center;
        display: block;
      }

      .error_tip {
        height: 15px;
        margin-top: 10px;
        color: #f30213;

        i {
          margin-right: 10px;
        }
      }

      .sms_code_con {
        width: 100%;

        .get_sms {
          width: 100px;
          height: 40px;
          line-height: 38px;
          background: #e73539;
          text-align: center;
          color: white;
          font-size: 14px;
          border-radius: 0 3px 3px 0;
        }
      }

      .margin {
        margin-top: 20px;
      }

      .next {
        width: 170px;
        height: 40px;
        background: #f30213;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: white;
        margin-top: 20px;
        line-height: 40px;
        border-radius: 3px;
        margin: 42px auto 0;
        cursor: pointer;
      }
    }

    .manage_tips {
      width: 938px;
      background: #fffdee;
      border: 1px solid #edd28b;
      padding: 15px 36px;
      margin-top: 117px;

      p {
        color: #555555;
        margin-top: 10px;
      }

      .tips_title {
        font-weight: bold;
        margin-bottom: 11px;
        margin-top: 0;
      }
    }
  }
}
</style>
<style lang="scss">
.sld_phone_mange {
  .el-input {
    width: 280px;
    height: 40px;
    border-radius: 3px 0 0 3px;
  }

  .el-input__inner {
    width: 280px;
    height: 40px;
    border-radius: 3px 0 0 3px;
  }

  .input {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    border-radius: 3px;

    .el-input__inner {
      height: 40px;
      border-radius: 3px;
      width: 100%;
    }
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/**
* 解决el-input设置类型为number时，中文输入法光标上移问题
**/
.el-input__inner {
  line-height: 1px !important;
}
</style>